@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,600,800');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700');

@font-face {
  font-family: "DINPro-Bold";
  src: url("../fonts/DINPro-Bold.ttf"),url("../fonts/DINPro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "DINPro";
  src: url("../fonts/DINPro.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

$fs: 19px;

$lh: 24px;

html,body{
  font-family: 'Open Sans', sans-serif;
  font-size: $fs;
  line-height: $lh;
  color: $white;
  @include media-breakpoint-down(md) {
    font-size: (#{$fs - 2px});
  }
  @include media-breakpoint-down(sm) {
    font-size: (#{$fs - 4px});
  }
}
