// header styles
.header{
  background: url(../images/header_bkg.jpg);
  background-position: center;
  color: $white;
  line-height: (#{$lh - 2px});
  .breadcumbs{
    // position: absolute;
    // bottom: 5px;
    ul{
      font-size: (#{$fs - 4px});
      color: $gray;
      display: flex;
      flex-flow: row wrap;
      &>li:first-child{
        padding-left: 40px;
      }
      a{
        color: $white;
        &:after{
          content: ">";
          margin: 0 10px;
        }
      }
    }
  }
  .container{
    display: flex;
    padding-top: 18px; 
    position: relative;
    padding-bottom: 55px;
    flex-flow: row nowrap;
    justify-content: space-between;
    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
    }
    @include media-breakpoint-down(sm) {
      font-size: (#{$fs - 4px});
    }
  }
  &--address{
    flex: 0 0 17%;
    @include media-breakpoint-down(sm) {
    flex: 0 0 40%;
    }
    &__item{
      position: relative;
      padding-left: 25px;
      &:before{
        display: block;
        position: absolute;
        content: " ";
        background-image: url("../images/icons/address.png");
        background-repeat: no-repeat;
        background-position: center;
        width: 10px;
        height: 14px;
        left: 5px;
        top: 5px;
      }
    }
    &__link{
      color: $white;
      position: relative;
      margin-top: 25px;
      padding-left: 45px;
      display: block;
      padding-bottom: 2px;
      text-decoration: underline;
      @include media-breakpoint-down(sm) {
        margin-top: 10PX;
      }
      &:before{
        display: block;
        position: absolute;
        content: " ";
        background-image: url("../images/icons/home.png");
        background-repeat: no-repeat;
        background-position: center;
        width: 25px;
        height: 23px;
        left: 5px;
        top: 0px;
      }
    }
  }
  &--logo{
    flex: 0 0 60%;
    margin-top: 10px;
    text-align: center;
    font-size: (#{$fs + 5px});
    font-weight: 900;
    img{
      margin: auto;
    }
    @include media-breakpoint-down(md){
      flex: 0 0 50%;
    }
    @include media-breakpoint-down(sm) {
      order: 2;
      flex: 0 0 100%;
    }
  }
  &--phone{
    flex: 0 0 14%;
    text-align: right;
    span{
      color: $red;
    }
    @include media-breakpoint-down(sm) {
      order: 1;
      flex: 0 0 40%;
    }

    &__btn{
      font-size: (#{$fs - 4px});
      margin-top: 15px;
      display: inline-block;
      background-color: $orange;
      color: $white;
      border-radius: 20px;
      padding: 8px 20px;
      white-space: nowrap;
      @include media-breakpoint-down(sm) {
        white-space: nowrap;
      }
    }
  }
}