.categories{
  // padding-bottom: 40px;
  .cat-header, .article {
    padding: 40px 0;
    text-align: center;
    @include media-breakpoint-down(sm) {
      padding: 0;
      padding-top: 20px;
    }
    h2{
      font-weight: 900;
      font-size: (#{$fs + 16px});
      @include media-breakpoint-down(sm) {
        font-size: (#{$fs + 10px});
      }
    }
  }
  .cat-list{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 100px;
    @include media-breakpoint-down(xs) {
      padding-bottom: 40px;
    }
  }
  .category{
    display: flex;
    margin: auto;
    flex-flow: column nowrap;
    align-items: center;
    color: $white;
    margin-bottom: 5px;
    width: 581px;
    height: 232px;
    position: relative;
    padding-left: 274px; 
    z-index: 5;
    @include media-breakpoint-down(sm) {
      width: 100%;
      padding-left: 50%; 
      height: auto;
      min-height: 110px;
      padding-top: 0px;
      margin-top: 40px;
    }
    &__bkg{
      position: absolute;
      z-index: -1;
      top: 0;
      right: 0;
      img{
        max-width: 100%;
      }
    }
    &__title{
      padding-top: 5px;
      padding-bottom: 15px;
      h2,span{
        font-weight: 900;
        font-size: (#{$fs + 16px});
        @include media-breakpoint-down(sm) {
          font-size: (#{$fs - 2px})!important;
        }
      }
    }
    &__desc{
      padding-top: 10px;
      color: $grfont;
      font-size: (#{$fs + 1px});
      @include media-breakpoint-down(sm) {
        font-size: (#{$fs - 8px});
        padding-top: 0px;
        line-height: 15px;
      }
    }
    &__time{
      position: absolute;
      right: 10px;
      bottom: 10px;
      font-size: (#{$fs - 1px});
      @include media-breakpoint-down(sm) {
        // display: none;
        bottom: -10px;
        font-size: (#{$fs - 6px});
      }
      img{
        margin-right: 10px;
      }
    }
  }
}