// footer styles
.footer{
  background-color: $footer;
  color: $white;
  &--block{
    display: flex;
    padding: 20px 0;
    flex-flow: row wrap;
    justify-content: space-between;
    &__addr{
      position: relative;
      padding-left: 25px;
      &:before{
        display: block;
        position: absolute;
        content: " ";
        background-image: url("../images/icons/address.png");
        background-repeat: no-repeat;
        background-position: center;
        width: 10px;
        height: 14px;
        left: 5px;
        top: 5px;
      }
    }
    &__mail{
      position: relative;
      padding-left: 35px;
      &:before{
        display: block;
        position: absolute;
        content: " ";
        background-image: url("../images/icons/mail.png");
        background-repeat: no-repeat;
        background-position: center;
        width: 21px;
        height: 15px;
        left: 5px;
        top: 5px;
      }
    }
    &__phone-block{
      display: flex;
      flex-flow: column nowrap;
      a{
        color: $orange;
        text-decoration: underline;
      }
    }
    &__insta{
      align-self: center;
    }
  }
}
