/* Hide scroll bar */

html.remodal-is-locked {
  overflow: hidden;
  touch-action: none;
}

/* Anti FOUC */

.remodal, [data-remodal-id] {
  display: none;
}

/* Necessary styles of the overlay */

.remodal-overlay {
  position: fixed;
  z-index: 9999;
  top: -5000px;
  right: -5000px;
  bottom: -5000px;
  left: -5000px;
  display: none;
}

/* Necessary styles of the wrapper */

.remodal-wrapper {
  position: fixed;
  z-index: 10000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  overflow: auto;
  text-align: center;
  -webkit-overflow-scrolling: touch;
  &:after {
    display: inline-block;
    height: 100%;
    margin-left: -0.05em;
    content: "";
  }
}

/* Fix iPad, iPhone glitches */

.remodal-overlay, .remodal-wrapper {
  backface-visibility: hidden;
}

/* Necessary styles of the modal dialog */

.remodal {
  position: relative;
  outline: none;
  text-size-adjust: 100%;
}

.remodal-is-initialized {
  /* Disable Anti-FOUC */
  display: inline-block;
}
   @keyframes "remodal-opening-keyframes" {
    from {
      transform: scale(1.05);
      opacity: 0;
    }
    to {
      transform: none;
      opacity: 1;
      filter: blur(0);
    }
  }
  @keyframes "remodal-closing-keyframes" {
    from {
      transform: scale(1);
      opacity: 1;
    }
    to {
      transform: scale(0.95);
      opacity: 0;
      filter: blur(0);
    }
  }
  @keyframes "remodal-overlay-opening-keyframes" {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes "remodal-overlay-closing-keyframes" {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  /* Media queries
     ========================================================================== */
  /* IE8
     ========================================================================== */
  .remodal-bg.remodal-is-opening {
    filter: blur(3px);
  }
  .remodal-bg.remodal-is-opened {
    filter: blur(3px);
  }
  .remodal-overlay {
    background: rgba(23, 25, 29, 0.9);
  }
  .remodal-overlay.remodal-is-opening {
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-name: remodal-overlay-opening-keyframes;
  }
  .remodal-overlay.remodal-is-closing {
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-name: remodal-overlay-closing-keyframes;
  }
  .remodal-wrapper {
    padding: 10px 10px 0;
    &:after {
      vertical-align: middle;
    }
  }
  .remodal {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 10px;
    padding: 30px;
    transform: translate3d(0, 0, 0);
    color: #2b2e38;
    background: #fff;
    vertical-align: middle;
  }
  .remodal.remodal-is-opening {
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-name: remodal-opening-keyframes;
  }
  .remodal.remodal-is-closing {
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-name: remodal-closing-keyframes;
  }
  .remodal-close {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    overflow: visible;
    margin: 0;
    padding: 0;
    width: 50px;
    height: 50px;
    cursor: pointer;
    transition: color 0.2s;
    text-decoration: none;
    color: #95979c;
    border: 0;
    outline: 0;
    background: transparent;
    &:hover {
      color: #2b2e38;
      &:before {
        background-image: url("../images/content/x-red.svg");
      }
    }
    &:focus {
      color: #2b2e38;
      
    }
    &:before {
      background-image: url("../images/content/x.svg");
      position: absolute;
      top: 20px;
      right: 20px;
      display: block;
      width: 20px;
      height: 20px;
      content: "";
      text-align: center;
    }
    &::-moz-focus-inner {
      padding: 0;
      border: 0;
    }
  }
  .remodal-confirm {
    font: inherit;
    display: inline-block;
    overflow: visible;
    min-width: 110px;
    margin: 0;
    padding: 12px 0;
    cursor: pointer;
    transition: background 0.2s;
    text-align: center;
    vertical-align: middle;
    text-decoration: none;
    border: 0;
    outline: 0;
    color: #fff;
    background: #81c784;
    &:hover {
      background: #66bb6a;
    }
    &:focus {
      background: #66bb6a;
    }
    &::-moz-focus-inner {
      padding: 0;
      border: 0;
    }
  }
  .remodal-cancel {
    font: inherit;
    display: inline-block;
    overflow: visible;
    min-width: 110px;
    margin: 0;
    padding: 12px 0;
    cursor: pointer;
    transition: background 0.2s;
    text-align: center;
    vertical-align: middle;
    text-decoration: none;
    border: 0;
    outline: 0;
    color: #fff;
    background: #e57373;
    &:hover {
      background: #ef5350;
    }
    &:focus {
      background: #ef5350;
    }
    &::-moz-focus-inner {
      padding: 0;
      border: 0;
    }
  }
  .lt-ie9 {
    .remodal-overlay {
      background: #2b2e38;
    }
    .remodal {
      width: 700px;
    }
  }
  @media only screen and (min-width: 641px) {
    .remodal {
      max-width: 700px;
    }
  }
  .question{
    max-width: 470px;
    width: 100%;
    border-radius: 6px;
    .header-container{
      margin-top: 10px;
      margin-bottom: 30px;
    }
    .modal-input-block{
      max-width: 307px;
      margin: 0 auto;
      margin-bottom: 10px;
      &__label{
        margin-top: 0px;
        font-size: 14px;
        text-align: left;
      }
      &_error{
        input{
        border-color: $buttons;
        }
      }
      &__modal-input{
        input{
          font-size: 18px;
          height: 55px;
          width: 100%;
          color: $black;
          border-radius: 4px;
          &::-webkit-input-placeholder { 
            color: $placeholder;
          }
          &::-moz-placeholder { 
            color: $placeholder;
          }
          &:-ms-input-placeholder { 
            color: $placeholder;
          }
          &:-moz-placeholder { 
            color: $placeholder;
          }
          
        }
        textarea{
          height: 85px;
          font-size: 18px;
          width: 100%;
          border-radius: 4px;
          margin-bottom: -10px;
        }
      }
    }
    .modal-submit-block{
      max-width: 307px;
      margin: 0 auto;
      padding-bottom: 20px;
      &__modal-button{
        margin-top: 20px;
        height: 60px;
       
      }
      &__license{
        margin-top: 20px;
        font-size: 12px;
        text-align: left;
        line-height: 16px;
        color: $placeholder;
      }
    }
  }
  .question-submit{
    .success{
      &__header-min{
        margin-top: 20px;
      }
      &__icon{
        margin-top: 20px;
        svg{
          width: 120px;
          height: 120px;
        }
      }
      &__text{
        padding-top: 10px;
        padding-bottom: 20px;
        line-height: 30px;
      }
    }
  }