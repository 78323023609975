.auto-block{
  display: flex;
  flex-flow: row nowrap;
  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
  }
}
.automodule{
  background-position: center bottom;
  background-repeat: no-repeat;
  position: relative;
  margin: auto;
  max-width: 100%;
  background-size: contain;
  @include media-breakpoint-down(sm) {
    display: none;
  }
  &__item{
    position: absolute;
    background-image: url("../images/automodule_bkg.png");
    background-repeat: no-repeat;
    background-position: 10px bottom; 
    max-width: 120px;
    padding-left: 30px;
  }
}