// Normalize Styles
@import 'node_modules/normalize.css/normalize';
// Box sizing partial styles:
// Apply a natural box layout model to all elements
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

// Styling on warning for users on IE7 or below
.browsehappy {
  text-align: center;
  margin: 0;
  background: #fff8e2;
  color: #000;
  padding: 0.5em 0;
}

@import './colors';
@import './bootstrap';
@import './reset';
@import './typografy';

// Import Modules
@import '../_modules/header/header';
@import '../_modules/footer/footer';
@import '../_modules/slider/slider';
@import 'node_modules/slick-carousel/slick/slick.scss';
@import 'node_modules/slick-carousel/slick/slick-theme.scss';
@import '../_modules/categories/categories';
@import '../_modules/automodule/automodule';
@import '../_modules/price/price';
@import '../_modules/manufacturer/manufacturer';
@import '../_modules/modal/modal';

html,body{
  min-height: 100%;
  height: 100%;
}
.wrapper{
  position: relative;
  // height: 100%;
  min-height: 100%;
  width: 100%;
}
html,body{
  min-height: 100%;
  height: 100%;
  color: $black;
  img{
    max-width: 100%;
  }
}
.content{
  background-image: url("../images/body_bkg.jpg");
  background-size: cover;
  background-position: 50% 0;
}
.article h2{
  
}
.allcats{
  margin: 40px auto;
  padding: 10px 60px;
}
input[type=text], input[type=password], input[type=email], input[type=phone], input[type=file], input[type=number] {
  outline: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin:0;}

input[type=number] {
  -moz-appearance:textfield;
}
input, textarea{
  padding: 0 10px;
  margin: 0;
  outline: none;
  background: none;
  border: 1px solid $black;
  &::-webkit-input-placeholder       {color: $gray; opacity: 0.8; transition: opacity 0.3s ease;}
  &::-moz-placeholder                {color: $gray; opacity: 0.8; transition: opacity 0.3s ease;}
  &:-moz-placeholder                 {color: $gray; opacity: 0.8; transition: opacity 0.3s ease;}
  &:-ms-input-placeholder            {color: $gray; opacity: 0.8; transition: opacity 0.3s ease;}
  &:focus::-webkit-input-placeholder {opacity: 0; transition: opacity 0.3s ease;}
  &:focus::-moz-placeholder          {opacity: 0; transition: opacity 0.3s ease;}
  &:focus:-moz-placeholder           {opacity: 0; transition: opacity 0.3s ease;}
  &:focus:-ms-input-placeholder      {opacity: 0; transition: opacity 0.3s ease;}
}
select {
  outline: none;
} 
ul{
  list-style: none;
  padding: 0;
  margin: 0;
}
a{
  text-decoration: none;
  &:hover{
    text-decoration: none;
    opacity: 0.85;
    color: $white;
  }
}
.slick-dots {
  bottom: 25px;
}
.slick-dots li button::before{
  font-size: 36px;
  color: gray;
  opacity: 1;
}