.price{
  margin-top: 40px;
  // border: 1px solid $black;
  &>div{
    display: flex;
    flex-flow: row nowrap;
  }
  &--head{
    font-weight: 900;
    font-size: (#{$fs + 5px});
    @include media-breakpoint-down(sm) {
      font-size: (#{$fs - 4px});
    }
    border-bottom: 1px solid $black;
    &__col1{
      flex: 0 0 60%;
      padding: 20px 15px;
    }
    &__col2{
      text-align: center;
      flex: 0 0 20%;
      padding: 20px 15px;
    }
    &__col3{
      flex: 0 0 20%;
      padding: 20px 15px;
      text-align: center;
    }
    @include media-breakpoint-down(xs) {
      font-size: (#{$fs - 4px});
      &>div{
        padding:0;
      }
    }
  }
  &--row{
    font-weight: 600;
    font-size: (#{$fs - 1px});
    border-bottom: 1px solid $black;
    @include media-breakpoint-down(sm) {
      font-size: (#{$fs - 4px});
    }
    &__col1{
      flex: 0 0 60%;
      padding: 20px 15px;
      border-left: 1px solid $black;
      border-right: 1px solid $black;
    }
    &__col2{
      flex: 0 0 20%;
      text-align: center;
      padding: 20px 15px;
      border-right: 1px solid $black;
    }
    &__col3{
      flex: 0 0 20%;
      padding: 20px 15px;
      text-align: center;
      border-right: 1px solid $black;
    }
    @include media-breakpoint-down(xs) {
      font-size: (#{$fs - 4px});
      &>div{
        padding:0;
      }
    }
  }
}