.manufacturer{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 40px 0;
  &__header{
    flex: 0 0 100%;
    font-weight: 900;
    font-size: (#{$fs + 5px});
    padding-bottom: 20px;
  }
}